.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}


.wrapper {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 30px;
  width: 400px;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}

.title span {
  color: #7286d3;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.row i {
  font-size: 20px;
  color: #7286d3;
  margin-right: 10px;
}

input[type="text"],
input[type="password"] {
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #f2f2f2;
  width: 100%;
}

input[type="submit"] {
  background-color: #7286d3;
  border: none;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

input[type="submit"]:hover {
  background-color: #5f71bb;
}

.alert-box-login {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 300px;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 5px;
  padding: 10px;
  color: #842029;
  
}

.close-btn {
  float: right;
  cursor: pointer;
}

.close-btn:hover {
  color: red;
}

.App::-webkit-scrollbar {
  display: none;
}




.order-page{
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
}
.orders {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.order {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 5px;
  padding: 20px;
  position: relative;
}

.order.new {
  border: 2px solid #007bff;
}

.order.ready {
  border: 2px solid #28a745;
}

.order.served {
  display: none;
}

.order-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
}


.order-status-new{
  background-color:  #f8d7da;
  color: #842029;
  padding: 16px;
  text-align: center;
  font-size: 16px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.order-status-cook{
  background-color:  #d1e7dd;
  color: #0f5132;
  padding: 16px;
  text-align: center;
  font-size: 16px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.order-items {
  border-collapse: collapse;
  width: 100%;
}

.order-items th,
.order-items td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  }
  
  .order-items th {
  background-color: #f2f2f2;
  color: #333;
  }
  
  .order-items td.quantity,
  .order-items td.price {
  text-align: right;
  }
  
  .order-items td.subtotal {
  font-weight: bold;
  }
  
  .order-items td.remove-item {
  text-align: center;
  }
  
  .order-items .product-thumbnail {
  width: 60px;
  height: 60px;
  display: inline-block;
  margin-right: 10px;
  }
  
  .order-items .product-name {
  font-weight: bold;
  margin-bottom: 5px;
  }
  
  .order-items .product-price {
  font-weight: bold;
  }

  .no-orders {
    width: 80%;
    text-align: center;
    margin: auto;
  }

  .switch {
    position: relative;
    width: 50px;
    height: 28px;
    display: flex;
    align-items: center;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 2rem;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    border-radius: 100%;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .alert-box {
    position: fixed;
    bottom: 10px;
    left: 10px;
    width: 300px;
    background-color: #d1e7dd;
    border: 1px solid #badbcc;
    border-radius: 0.375rem;
    padding: 10px;
    color: #0f5132;
    
  }

  .served-div{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  .alert-box-login {
    position: fixed;
    bottom: 10px;
    left: 10px;
    width: 300px;
    background-color: #f8d7da;
    border: 1px solid #f5c2c7;
    border-radius: 5px;
    padding: 10px;
    color: #842029;
    
  }



  /* logout */

  .logout-button {
    /* border: none; */
    /* background-color: #2cb879; */
    /* color: #fff; */
    /* font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease; */
    position: absolute;
    right: 2%;
    top:10px;
  }
  
  .logout-button:hover {
    background-color: #c0392b;
  }
  
  .logout-button:active {
    background-color: #e74c3c;
    transform: translateY(1px);
  }
  
  
  
  
  
  
  